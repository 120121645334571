module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Nikolay Lechev","short_name":"Nikolay Lechev","description":"I’m Nikolay, a product designer who can code. I help startups shape, build, and scale their digital products.","theme_color":"#333333","background_color":"#fbe8de","icon":"static/meta-images/icon-with-bg.png","include_favicon":false,"start_url":"/","display":"minimal-ui","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"cacheDigest":"dedc8708e1f60705e5161de6fbde49c7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
